<template>
  <div class="home">
    <div class="header_top">
      <handleback></handleback>
      <div >兑换红包</div>
      <div>12322</div>
    </div>

    
  </div>
</template>

<script>

import {} from "@/api/index";
export default {
  data() {
    return {
        kami:''
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.pay {
  margin: 0 auto;
  @media (max-width: 550px) {
    width: 140px;
  }
}
.home {
  margin: 0 auto;
  width: 95%;

  .header_top {
    width: 100%;
    height: 56px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child {
    }

    div:last-child {
      color: #fff;
      font-size: 12px;
      text-decoration: underline;
      line-height: 1.5;
      opacity: 0;
    }
  }
}
.tab {
  // width: 399px;
  width: 300px;
  height: 42px;
  background: #323436;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  margin: 0 auto;
  transition: all 0.3s linear;

  color: #727477;
  font-size: 14px;
  position: relative;
  @media (max-width: 550px) {
    // width: 285px;
    width: 215px;
    height: 30px;
  }
}
.btn {
  width: 98px;
  height: 35px;
  position: relative;
  z-index: 2;
  // border: 1px solid red;
  @media (max-width: 550px) {
    width: 70px;
    height: 25px;
    font-size: 10px;
  }
  .icon {
    width: 28px;
    height: 28px;
    margin: 5px;
    @media (max-width: 550px) {
      width: 20px;
      height: 20px;
    }
  }
}
.slider {
  position: absolute;
  width: 99px;
  height: 35px;
  background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
  border-radius: 70px;
  transition: left 0.2s ease-in-out;
  @media (max-width: 550px) {
    width: 70px;
    height: 25px;
  }
}
.active {
  color: #fff;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: #fff;
      transition: all 0.2s ease-in-out;
    }
  }
}
.header {
  margin-top: 29px;
}
.card {
  width: 147px;
  height: 98px;
  background: #323436;
  border-radius: 14px 14px 14px 14px;
  position: relative;
  margin: 0 auto;
  color: #727477;
  font-weight: 700;
  font-size: 35px;
  // padding-top: 20px;
  @media (max-width: 550px) {
    // padding-top: 20px;

    font-size: 25px;
    width: 100%;
    height: 70px;
  }
}
.prz {
  width: 98px;
  height: 35px;
  background: linear-gradient(90deg, #c23dd4 0%, #6f4fff 100%);
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 14px 14px 14px 14px;
  color: #fff;
  font-size: 14px;
  @media (max-width: 550px) {
    width: 70px;
    height: 25px;
    font-size: 10px;
  }
  .icon {
    width: 21px;
    height: 21px;
    margin: 0;
    @media (max-width: 550px) {
      width: 15px;
      height: 15px;
    }
  }
}
.main_center_bottom {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  .select {
    background: #3a97e4 !important;
    border: 2px solid #3a97e4 !important;
  }
  div:first-child {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #727477;
    box-sizing: border-box;
    margin-top: 5px;
    @media (max-width: 550px) {
      margin-top: 3px;
      width: 8px;
      height: 8px;
      border: 1px solid #727477;
    }
  }
  div:last-child {
    width: 95%;
    color: #ffffff;
    font-size: 16.8px;
    text-align: left;
    word-spacing: -2px;
    @media (max-width: 550px) {
      font-size: 12px;
    }
  }
}
.main {
  width: 100%;
  height: 56px;
  background: #585a60;
  border-radius: 35px 35px 35px 35px;
  opacity: 1;
  margin: 0 auto;
  margin-top: 29px;

  font-size: 21px;
  padding: 0 66px 0 33px;

  @media (max-width: 550px) {
    margin-top: 20px;

    font-size: 15px;
    height: 40px;
    padding: 0 47px 0 24px;
  }
}
.bottom {
  width: 100%;
  height: 56px;
  background: linear-gradient(
    360deg,
    rgba(114, 116, 119, 0.2) 0%,
    rgba(114, 116, 119, 0.1) 100%
  );
  opacity: 1;
  border: 1px solid #727477;
  margin: 0 auto;
  margin-top: 14px;

  font-size: 25px;
  @media (max-width: 550px) {
    font-size: 18px;
    height: 40px;
  }
}
.actives {
  color: #fff;

  background: linear-gradient(
    -90deg,
    rgba(58, 151, 228, 0.3) 0%,
    rgba(37, 244, 132, 0.3) 100%
  );
  opacity: 1;
  border: 1px solid;
  // border-image: linear-gradient(
  //     270deg,
  //     rgba(37.00000159442425, 244.0000006556511, 132.0000073313713, 1),
  //     rgba(58.00000034272671, 151.00000619888306, 228.0000016093254, 1)
  //   )
  //   1 1;
  border-left-color: rgba(37, 244, 132, 1);
  border-right-color: rgba(58, 151, 228, 1);
  border-top-color: rgba(37, 244, 132, 1);
  border-bottom-color: rgba(58, 151, 228, 1);
}
.activess {
  background: linear-gradient(
    360deg,
    rgba(37, 244, 132, 0.2) 0%,
    rgba(37, 244, 132, 0.1) 100%
  );
  opacity: 1;
  border: 1px solid #25f484;
  transition: all 0.3s ease-in-out;
}
.footer {
  margin-top: 40px;
  font-size: 18px;

  @media (max-width: 550px) {
    font-size: 13px;
  }
}
.footer_x {
  width: 329px;
  margin: 0 auto;
  color: #25f484;
  @media (max-width: 550px) {
    width: 237px;
  }
  .footer_x_u {
    width: 100%;
    height: 3px;
    background-color: #25f484;
    border-radius: 50px;
  }
}
.footer_d {
  margin: 0 auto;

  // width: 95px;
  color: #727477;
  @media (max-width: 550px) {
    width: 71px;
  }
  .footer_d_u {
    width: 100%;
    height: 3px;
    background-color: #727477;
    border-radius: 50px;
  }
}
.out_II {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;
  @media (max-width: 550px) {
    width: 100%;
  }
  .out_II_I {
    width: 95%;
    margin: 0 auto;
    position: relative;

    height: 315px;
    background: #101115;
    border-radius: 23px 23px 23px 23px;
    margin-top: 211px;
    overflow: hidden;
    justify-content: space-around;
    @media (max-width: 550px) {
      margin-top: 150px;
      height: 251px;
    }
    .out_II_I_I {
      font-size: 28px;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 20px;
      }
    }
    .out_II_I_II {
      width: 89%;
      height: 56px;
      @media (max-width: 550px) {
        height: 40px;
      }
      input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #323436;
        border-radius: 35px 35px 35px 35px;
        border: none;
        outline: none;
        font-size: 21px;
        color: #fff;
        text-align: center;
      }
      input::-webkit-input-placeholder {
        text-align: center;
        font-size: 21px;
        color: #fff;
        @media (max-width: 550px) {
          font-size: 15px;
        }
      }
    }
    .out_II_I_III {
      width: 89%;
      height: 56px;
      input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #323436;
        border-radius: 20px;
        border: none;
        outline: none;
        font-size: 21px;
        color: #fff;
        text-align: center;
        @media (max-width: 550px) {
          height: 40px;
        }
      }
      input::-webkit-input-placeholder {
        text-align: center;
        font-size: 21px;
        color: #fff;
        @media (max-width: 550px) {
          font-size: 15px;
        }
      }
    }
    .out_II_I_IIII {
      width: 89%;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      font-size: 21px;

      .btn_c {
        width: 196px;
        height: 42px;

        background: linear-gradient(
          360deg,
          rgba(114, 116, 119, 0.5) 0%,
          rgba(114, 116, 119, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #727477;
        @media (max-width: 550px) {
          width: 140px;
          height: 30px;

          font-size: 15px;
        }
      }
      .btn_s {
        width: 196px;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #25f484;
        @media (max-width: 550px) {
          width: 140px;
          height: 30px;

          font-size: 15px;
        }
      }
    }
  }
  .out_II_I::before {
    content: ""; /*必须设置content属性*/
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -222deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 40%,
      rgba(248, 125, 81, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>
